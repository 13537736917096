/* eslint-disable */

<template>
  <div>
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag">
      <div :class="{ 'cursor-drag': draggingCard === true }" class="vx-row">
        <div class="vx-col lg:w-4/12 md:w-4/12 sm:w-5/5">
          <vx-card class="mb-base">
            <div class="vx-row mb-4">
              <div class="vx-col w-1/4 flex items-center justify-center">
                <font-awesome-icon icon="fa-sharp fa-solid fa-server" style="background: #181d2a" class="h-8 w-8 p-4 rounded-full" />
              </div>
              <div class="vx-col w-3/4">
                <span class="text-xl font-bold my-1 flex gap-x-2"
                  >VM NAME:
                  <p class="font-light">MS1</p></span
                >
                <span class="text-xl font-bold my-1 flex gap-x-2"
                  >IP:
                  <p class="font-light">10.1.0.1</p></span
                >
                <span class="text-xl font-bold my-1 flex gap-x-2"
                  >OS:
                  <p class="font-light">Windows Server</p></span
                >
              </div>
            </div>
            <div class="vx-row mb-4">
              <div class="vx-col w-1/4 flex items-center justify-center">
                <font-awesome-icon icon="fa-sharp fa-solid fa-server" style="background: #181d2a" class="h-8 w-8 p-4 rounded-full" />
              </div>
              <div class="vx-col w-3/4">
                <span class="text-xl font-bold my-1 flex gap-x-2"
                  >VM NAME:
                  <p class="font-light">DC1</p></span
                >
                <span class="text-xl font-bold my-1 flex gap-x-2"
                  >IP:
                  <p class="font-light">10.1.0.2</p></span
                >
                <span class="text-xl font-bold my-1 flex gap-x-2"
                  >OS:
                  <p class="font-light">Windows Server</p></span
                >
              </div>
            </div>
            <div class="vx-row mb-6">
              <div class="vx-col w-1/4 flex items-center justify-center">
                <font-awesome-icon icon="fa-sharp fa-solid fa-server" style="background: #181d2a" class="h-8 w-8 p-4 rounded-full" />
              </div>
              <div class="vx-col w-3/4">
                <span class="text-xl font-bold my-1 flex gap-x-2"
                  >VM NAME:
                  <p class="font-light">KLX1</p></span
                >
                <span class="text-xl font-bold my-1 flex gap-x-2"
                  >IP:
                  <p class="font-light">10.1.0.192</p></span
                >
                <span class="text-xl font-bold my-1 flex gap-x-2"
                  >OS:
                  <p class="font-light">Kali Linux</p></span
                >
              </div>
            </div>
            <h3 class="font-semibold mb-2">For each VM, what is the current OS Version?</h3>
            <div class="mb-4">
              <vue-draggable-container id="wordBank" class="mt-1 border border-white wordBank">
                <h5 class="dragBlock font-semibold cursor-pointer text-center ml-0">2018</h5>
                <h5 class="dragBlock font-semibold cursor-pointer text-center">2016</h5>
                <h5 class="dragBlock font-semibold cursor-pointer text-center mr-0">2008</h5>
              </vue-draggable-container>
            </div>
            <div class="vx-row p-2 pb-0">
              <div class="vx-col w-1/3 p-2">
                <h4>MS1</h4>
                <vue-draggable-container
                  id="input0"
                  class="answerStandard dropzone dropzoneStandard answer-bg w-full"
                ></vue-draggable-container>
              </div>
              <div class="vx-col w-1/3 p-2">
                <h4>DC1</h4>
                <vue-draggable-container
                  id="input1"
                  class="answerStandard dropzone dropzoneStandard answer-bg w-full"
                ></vue-draggable-container>
              </div>
              <div class="vx-col w-1/3 p-2">
                <h4>KLX1</h4>
                <vue-draggable-container
                  id="input2"
                  class="answerStandard dropzone dropzoneStandard answer-bg w-full"
                ></vue-draggable-container>
              </div>
            </div>
          </vx-card>
        </div>
        <div class="vx-col lg:w-8/12 md:w-8/12 sm:w-5/5">
          <div style="height: 35rem">
            <vue-command
              id="command_prompt"
              :commands="commands"
              :prompt="'C:\\Users\\' + activeUserInfo.firstName.toLowerCase().replace(/ /g, '') + '>'"
              :show-intro="true"
              class=""
              intro="Microsoft Windows [Version 10.0.18363.1256] (c) 2019 Microsoft Corporation. All rights reserved."
              style="background-color: #111111; border: 1px solid #181d2a; border-radius: 0.5rem"
              title="Command Prompt"
            />
          </div>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';
import VueCommand, { createStdout, createStderr } from 'vue-command';
import 'vue-command/dist/vue-command.css';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },

      commands: {
        mema: () => createStdout('Is the best developer in the world'),
        help: () => createStdout('Available commands:<br>&nbsp;nmap [args] [target]<br>'),
        nmap(x) {
          if (x.A !== undefined) {
            x.a = x.A;
          }

          if (x.A === undefined) {
            return createStderr('Invalid Usage: nmap [args] [target]');
          }

          if (x.A === '10.1.0.1') {
            return createStdout(`<br><br><br>-----------------------------------------------<br>Starting Nmap 7.80 ( https://nmap.org ) at 2021-01-11 20:01 PST<br>
Nmap scan report for 10.1.0.1<br>
Host is up (0.00042s latency).<br>
Not shown: 998 closed ports<br>
PORT STATE SERVICE VERSION<br>
25/tcp open smtp hMailServer<br>
53/tcp open tcpwrapped<br>
MAC Address: 00:15:5D:01:CA:32 (Microsoft)<br>
Device type: general purpose<br>
Running: Microsoft Windows 2016<br>
OS CPE: cpe:/o:microsoft:windows_server_2016<br>
Os details: Microsoft Windows Server 2016<br>
Network Distance: 1 hop<br>Service Info: OSs: Windows, Windows Server 2016;CPE: cpe:/o:microsoft:windows<br>TRACEROUTE<br>НОP RTT   ADDRESS<br>1 0.41 ms 10.1.0.1<br>          os and Service detection performed. Please report any incorrect results at https://nmap.org/submit/<br>       Nmap done: 1 IP address (1 host up) scanned in 7.11 seconds<br>
-----------------------------------------------<br><br><br>`);
          }

          if (x.A === '10.1.0.2') {
            return createStdout(`<br><br><br>-----------------------------------------------<br>Starting Nmap 7.80 ( https://nmap.org ) at 2021-01-11 20:01 PST<br>
Nmap scan report for 10.1.0.2<br>
Host is up (0.00042s latency).<br>
Not shown: 998 closed ports<br>
PORT STATE SERVICE VERSION<br>
25/tcp open smtp aMailServer<br>
53/tcp open domain<br>
MAC Address: 00:15:5D:01:CA:32 (Microsoft)<br>
Device type: general purpose<br>
Running: Microsoft Windows 2008<br>
OS CPE: cpe:/o:microsoft:windows_server_2008r2<br>
Os details: Microsoft Windows Server 2008<br>
Network Distance: 1 hop<br>
Service Info: Host: mail.515support.com; OSs: Windows, Windows Server 2008 r2;CPE: cpe:/o:microsoft:windows<br>
TRACEROUTE<br>
НОP RTT   ADDRESS<br>
1 0.41 ms 10.1.0.2<br>
os and Service detection performed. Please report any incorrect results at https://nmap.org/submit/<br>
Nmap done: 1 IP address (1 host up) scanned in 7.11 seconds<br>-----------------------------------------------<br><br><br>`);
          }

          if (x.A === '10.1.0.192') {
            return createStdout(`<br><br><br>-----------------------------------------------<br>Starting Nmap 7.80 ( https://nmap.org ) at 2021-01-11 20:01 PST<br>
Nmap scan report for 10.1.0.192<br>
Host is up (0.00042s latency).<br>
Not shown: 999 closed ports<br>
PORT STATE SERVICE VERSION<br>
22/tcp open ssh<br>
ssh-hostkey:<br>
STATE SERVICE VERSION<br>
OpenSSH 8.1p1 Debian 1 (protocol 2.0)<br>
3072 21:04:d8:2d:93:41:11:fb:6b:ee:b5:5c:d8:81:d3:ee (RSA)<br>
256 03:39:3b:c4:df:0a:01:be:59:79:a4:73:3d:2f:c9:e9 (ECDSA)<br>
İ. 256 f9:51:c2:5c:8a:13:b4:c5:b3:e5:16:1f:ae:f8:5e :47 (ED25519)<br>
Device type: general purpose<br>
Running: Linux 2.6.X<br>
OS CPE: cpe:/0:linux:linux_kernel:2.6.32<br>
OS details: Kali Linux 2018.2<br>
Network Distance: e hops<br>
Service Info: OS: Linux; CPE: cpe:/o:linux:linux_kernel<br>
os and Service detection performed. Please report any incorrect results at https://nmap.org/submit/ .<br>
Nmap done: 1 IP address (1 host up) scanned in 2.28 seconds<br>-----------------------------------------------<br><br><br>`);
          }

          return createStdout('Invalid IP');
        },
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    markSimulation() {
      let totalScore = 0;

      if (document.getElementById('input0').textContent === '2016') {
        document.getElementById('input0').style.borderColor = '#28C76F';
        document.getElementById('input0').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input0').style.borderColor = '#fd4445';
        document.getElementById('input0').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input1').textContent === '2008') {
        document.getElementById('input1').style.borderColor = '#28C76F';
        document.getElementById('input1').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input1').style.borderColor = '#fd4445';
        document.getElementById('input1').style.backgroundColor = '#fd4445';
      }

      if (document.getElementById('input2').textContent === '2018') {
        document.getElementById('input2').style.borderColor = '#28C76F';
        document.getElementById('input2').style.backgroundColor = '#28C76F';
        totalScore++;
      } else {
        document.getElementById('input2').style.borderColor = '#fd4445';
        document.getElementById('input2').style.backgroundColor = '#fd4445';
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    draggable,
    Prism,
    VueCommand,
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
#wordBank {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.6rem;
  min-height: unset !important;
}
</style>
